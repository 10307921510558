import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { GlobalFontStyles } from './components/Styles';

const urlParams = new URLSearchParams(window.location.search);
const eventCuid = urlParams.get('event-cuid');
const coachCuid = urlParams.get('coach-cuid');

const rootContainer = document.getElementById('root');

if (rootContainer) {
  ReactDOM.render(
    <React.StrictMode>
      <GlobalFontStyles />
      <App eventCuid={eventCuid} coachCuid={coachCuid} />
    </React.StrictMode>,
    rootContainer,
  );
}

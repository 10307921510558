import styled from 'styled-components';
import { Typography } from './Styles';
import { FC } from 'react';

export const ProfileImageContainer = styled.div`
  height: 150px;
  width: 150px;
  border-radius: 50%;
  margin-bottom: -75px;
  z-index: 1;
  & div {
    align-items: center;
    border: 1px solid #ebebeb;
    border-radius: 50%;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    background-color: white;
    display: flex;
    height: 100%;
    justify-content: center;
    overflow: hidden;
    width: 100%;
  }
  & img {
    width: 100%;
  }
`;
interface ProfileImageProps {
  imageUrl?: string | undefined | null;
  linkUrl?: string;
  initials?: string | undefined | null;
}
const ProfileImage: FC<ProfileImageProps> = ({ imageUrl, initials }) => {
  return (
    <ProfileImageContainer>
      {initials && !imageUrl ? (
        <div>
          <Typography>{initials}</Typography>
        </div>
      ) : (
        <div>
          <img alt="Profile Circle" src={imageUrl as string} />
        </div>
      )}
    </ProfileImageContainer>
  );
};

export default ProfileImage;

import React from 'react';
import styled from 'styled-components';
import {
  BrandTypography,
  Center,
  CoachMainContainer,
  disabledStyle,
  H2,
  HR,
  SmallButton,
  SmallLink,
  SmallTypography,
  Typography,
} from './Styles';
import ProfileImage from './ProfileImage';
import { useQuery } from 'react-query';
import { CoachType, getCoach } from '../lib/queries';
import { Loader } from '../App';
import {
  DOMAIN,
  getCoachingSummary,
  getCoachLocationText,
  getIsStripeConnectAccountValid,
  getLessonPrice,
  getUserFullName,
} from '../lib/utils';

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  flex: 1;
`;

export const DEFAULT_EVENT_IMAGE =
  'https://res.cloudinary.com/goreggy/image/upload/v1605494962/egooenxaqt8hdubxhx3l.svg';

const CoachEmbed: React.FC<{ cuid: string }> = ({ cuid }) => {
  const { data: coachData, isFetching } = useQuery<CoachType>(cuid, () => getCoach(cuid));
  if (!coachData) {
    if (!coachData && !isFetching) {
      return (
        <Center style={{ flexDirection: 'column' }}>
          <Typography>No coach found</Typography>
          <SmallLink
            target={'_blank'}
            rel={'noopener nofollow noreferrer'}
            href={'https://www.goreggy.com'}
          >
            Go to Reggy
          </SmallLink>
        </Center>
      );
    }
    return (
      <CoachMainContainer>
        <Loader />
      </CoachMainContainer>
    );
  }

  const coachSummaryText = getCoachingSummary({
    coachingVibe: coachData.coach?.coachingVibe,
    coachDisciplines: coachData.coach?.coachDisciplines,
  });
  const hasOrgToDisplay = Boolean(coachData.coach?.organizerCuidToDisplay);
  const organizerName = coachData.coach?.organizer?.name;
  const coachLocation = coachData.coach?.coachLocations?.[0];
  const coachLocationText = getCoachLocationText(coachLocation);
  const isStripeAccountValid = getIsStripeConnectAccountValid(coachData.stripeConnectAccount);

  // Link
  const organizerLink = `${DOMAIN}org/${coachData.coach.organizer?.slug ?? ''}`;
  const coachLink = `${DOMAIN}coach/${coachData.slug}`;

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {coachData.profilePicUrl && (
        <ProfileImage imageUrl={coachData.profilePicUrl} linkUrl={coachLink} />
      )}
      <CoachMainContainer>
        <ContentContainer style={{ marginTop: coachData.profilePicUrl ? '83px' : '0' }}>
          <H2 style={{ textAlign: 'center', wordBreak: 'break-all' }}>
            {getUserFullName(coachData)}
          </H2>
          {coachLocationText && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginBottom: '8px',
                marginTop: '4px',
                padding: '0 22px',
              }}
            >
              <span className="material-symbols-outlined">location_on</span>
              <div style={{ marginLeft: '2px' }}>
                <Typography style={{ textAlign: 'center', wordBreak: 'break-all' }}>
                  {coachLocationText}
                </Typography>
              </div>
            </div>
          )}
          {coachSummaryText && (
            <SmallTypography style={{ textAlign: 'center' }}>{coachSummaryText}</SmallTypography>
          )}
          {hasOrgToDisplay && (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <SmallLink
                target={'_blank'}
                rel={'noopener nofollow noreferrer'}
                href={organizerLink}
              >
                {organizerName}
              </SmallLink>
            </div>
          )}
          <div style={{ marginTop: '16px' }}>
            <HR />
          </div>
          {coachData.coach.coachLessons.map((lesson) => {
            return (
              <LessonItem
                key={lesson.cuid}
                allowRequests={coachData.coach.allowRequests}
                currencyCode={coachData.coach.currencyCode}
                isStripeAccountValid={isStripeAccountValid}
                coachLink={coachLink}
                lesson={lesson}
              />
            );
          })}
          <div style={{ marginTop: '16px' }}>
            <HR />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              marginTop: '8px',
            }}
          >
            <SmallLink target={'_blank'} rel={'noopener nofollow noreferrer'} href={coachLink}>
              View on Reggy
            </SmallLink>
            <BrandTypography
              target={'_blank'}
              rel={'noopener nofollow noreferrer'}
              href="https://www.goreggy.com"
            >
              Reggy
            </BrandTypography>
          </div>
        </ContentContainer>
      </CoachMainContainer>
    </div>
  );
};

interface LessonItemProps {
  allowRequests: boolean | undefined;
  hideRequestButton?: boolean;
  lesson: NonNullable<CoachType['coach']>['coachLessons'][0];
  isStripeAccountValid: boolean | undefined;
  currencyCode: string;
  coachLink: string;
}
const LessonItem: React.FC<LessonItemProps> = ({
  allowRequests,
  currencyCode,
  lesson,
  isStripeAccountValid,
  coachLink,
}) => {
  const isFree = !lesson.price;
  const priceText = getLessonPrice(lesson, currencyCode);
  const isDisabled = !allowRequests || (!isStripeAccountValid && !isFree);
  const lessonLink = coachLink + `?lesson=${lesson.cuid}`;
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginTop: '12px',
      }}
    >
      <div style={{ display: 'flex', flexDirection: 'column', paddingRight: '4px' }}>
        <SmallTypography style={{ wordBreak: 'break-word' }}>
          <b>{lesson.title}</b>
        </SmallTypography>
        <SmallTypography>{priceText}</SmallTypography>
      </div>
      <div>
        <SmallButton
          style={{ width: '100px', ...(isDisabled ? disabledStyle : {}) }}
          color="primary"
          target={'_blank'}
          rel={'noopener nofollow noreferrer'}
          href={lessonLink}
        >
          {allowRequests ? (isStripeAccountValid || isFree ? 'REQUEST' : 'UNAVAILABLE') : 'CLOSED'}
        </SmallButton>
      </div>
    </div>
  );
};
export default CoachEmbed;

import React from 'react';
import { Caption, SmallButton, SmallTypography } from './Styles';
import {
  buildClinicDays,
  DOMAIN,
  getTimezoneAbbreviation,
  initializeDateToTimezone,
} from '../lib/utils';
import { EventType } from '../lib/queries';
import { isBefore, subSeconds } from 'date-fns';
import { InstanceContainer } from './InstancesContainer';

const ClinicItem: React.FC<{
  event: EventType;
  clinic: EventType['clinics'][0];
  priceWithCurrency: string;
}> = ({ clinic, priceWithCurrency, event }) => {
  // Timezone
  const { eventVenues, eventVirtualVenues, isVirtualEvent } = event;
  const timezone = isVirtualEvent
    ? eventVirtualVenues?.[0]?.timezone
    : eventVenues?.[0]?.venue?.timezone;
  const timezoneAbbr = timezone ? getTimezoneAbbreviation(timezone) : null;

  // Can we still register for this clinic?
  const startDate = initializeDateToTimezone(clinic.clinicDays[0].startTime, timezone);
  const isPassedRegDeadline = isBefore(
    subSeconds(startDate, event.nonCategoriedRegDeadline ?? 0),
    new Date(),
  );

  const params = new URLSearchParams({
    clinic: clinic.cuid,
  });
  const registerLink = `${DOMAIN}org/${event.organizer.slug}/events/${
    event.eventMetadata.slug
  }/register?${params.toString()}`;

  return (
    <InstanceContainer>
      {buildClinicDays(clinic).map((day) => (
        <div key={day.dayText + day.timeRange}>
          <SmallTypography>
            <b>{day.dayText}</b>
          </SmallTypography>
          <SmallTypography>
            {day.timeRange} {timezoneAbbr}
          </SmallTypography>
        </div>
      ))}
      <div
        style={{
          marginTop: '-8px',
        }}
      >
        {clinic.spotsRemaining && (
          <Caption style={{ color: '#B48611' }}>
            {clinic.spotsRemaining} {clinic.spotsRemaining === 1 ? 'Spot' : 'Spots'} Remaining
          </Caption>
        )}
        <div
          style={{
            display: 'flex',
            marginBottom: '6px',
            flexDirection: 'column',
          }}
        >
          <SmallTypography>
            <b>{priceWithCurrency}</b> / person
          </SmallTypography>
        </div>
        <SmallButton target={'_blank'} rel={'noopener nofollow'} href={registerLink}>
          <span>{isPassedRegDeadline ? 'Closed' : 'Book'}</span>
        </SmallButton>
      </div>
    </InstanceContainer>
  );
};

export default ClinicItem;

import React, { Suspense } from 'react';
import EventEmbed from './components/EventEmbed';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  BrandTypography,
  Caption,
  CoachMainContainer,
  EventMainContainer,
} from './components/Styles';
import CoachEmbed from './components/CoachEmbed';
const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});

const App: React.FC<{ eventCuid: string | null; coachCuid: string | null }> = ({
  eventCuid,
  coachCuid,
}) => {
  if (!eventCuid && !coachCuid) {
    return <Loader />;
  }
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense
        fallback={
          <CoachMainContainer>
            <Loader />
          </CoachMainContainer>
        }
      >
        {eventCuid && (
          <EventMainContainer>
            <EventEmbed cuid={eventCuid} />
          </EventMainContainer>
        )}
        {coachCuid && <CoachEmbed cuid={coachCuid} />}
      </Suspense>
    </QueryClientProvider>
  );
};

export const Loader: React.FC = () => {
  return (
    <div
      style={{
        margin: 'auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <BrandTypography>Reggy</BrandTypography>
      <Caption style={{ color: '#898989' }}>Loading...</Caption>
    </div>
  );
};

export default App;

import React from 'react';
import { Caption, SmallButton, SmallTypography } from './Styles';
import {
  convertDateToTimeString,
  dateFromFloatingDateString,
  DOMAIN,
  getTimezoneAbbreviation,
  initializeDateToTimezone,
} from '../lib/utils';
import { EventType } from '../lib/queries';
import format from 'date-fns/format';
import { isBefore, subSeconds } from 'date-fns';
import { InstanceContainer } from './InstancesContainer';

/**
 * Ride day text
 */
export const buildRideDays = (
  ride: EventType['rides'][0],
): { dayText: string; timeText: string }[] => {
  const { rideDays } = ride;
  return rideDays.map((rideDay, index) => {
    // Build time
    const startTime = dateFromFloatingDateString(rideDay.startTime);
    const timeText = convertDateToTimeString(startTime);

    // Build day
    let dayText = format(startTime, 'EEE, MMM dd, yyyy');
    if (new Date().getFullYear() === startTime.getFullYear()) {
      dayText = format(startTime, 'EEE, MMM dd');
    }
    if (rideDays.length > 1) {
      dayText = `Day ${index + 1}: ${dayText}`;
    }
    return { dayText, timeText };
  });
};

const RideItem: React.FC<{
  event: EventType;
  ride: EventType['rides'][0];
  priceWithCurrency: string;
}> = ({ ride, event, priceWithCurrency }) => {
  // Timezone
  const { eventVenues, eventVirtualVenues, isVirtualEvent } = event;
  const timezone = isVirtualEvent
    ? eventVirtualVenues?.[0]?.timezone
    : eventVenues?.[0]?.venue?.timezone;
  const timezoneAbbr = timezone ? getTimezoneAbbreviation(timezone) : null;

  // Can we still register for this ride?
  const startDate = initializeDateToTimezone(ride.rideDays[0].startTime, timezone);
  const isPassedRegDeadline = isBefore(
    subSeconds(startDate, event.nonCategoriedRegDeadline ?? 0),
    new Date(),
  );

  const params = new URLSearchParams({
    ride: ride.cuid,
  });
  const registerLink = `${DOMAIN}org/${event.organizer.slug}/events/${
    event.eventMetadata.slug
  }/register?${params.toString()}`;

  return (
    <InstanceContainer>
      {buildRideDays(ride).map((day) => (
        <div key={day.dayText + day.timeText}>
          <SmallTypography>
            <b>{day.dayText}</b>
          </SmallTypography>
          <SmallTypography>
            {day.timeText} {timezoneAbbr}
          </SmallTypography>
        </div>
      ))}
      <div
        style={{
          marginTop: '-8px',
        }}
      >
        {ride.spotsRemaining && (
          <Caption style={{ color: '#B48611' }}>
            {ride.spotsRemaining} {ride.spotsRemaining === 1 ? 'Spot' : 'Spots'} Remaining
          </Caption>
        )}
        <div
          style={{
            display: 'flex',
            marginBottom: '6px',
            flexDirection: 'column',
          }}
        >
          <SmallTypography>
            <b>{priceWithCurrency}</b>
          </SmallTypography>
        </div>
        <SmallButton target={'_blank'} rel={'noopener nofollow'} href={registerLink}>
          <span>{isPassedRegDeadline ? 'Closed' : 'Book'}</span>
        </SmallButton>
      </div>
    </InstanceContainer>
  );
};

export default RideItem;

import styled, { createGlobalStyle, css, CSSProperties } from 'styled-components';
import SlickWoff from '../fonts/slick.woff';
import SlickEot from '../fonts/slick.eot';
import SlickSvg from '../fonts/slick.svg';
import SlickTtf from '../fonts/slick.ttf';

export const disabledStyle: CSSProperties = {
  color: 'rgba(0, 0, 0, 0.26)',
  boxShadow: 'none',
  backgroundColor: 'rgba(0, 0, 0, 0.12)',
  cursor: 'default',
  pointerEvents: 'none',
};

export const GlobalFontStyles = createGlobalStyle`
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    src: url(${SlickEot});
    src: url('${SlickEot}?#iefix') format('embedded-opentype'),
      url(${SlickWoff}) format('woff'), url(${SlickTtf}) format('truetype'),
      url('${SlickSvg}#slick') format('svg');
}
`;

const body1Css = css`
  color: #262626;
  font-size: 1rem;
  font-family: -apple-system, BlinkMacSystemFont, Open Sans, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 400;
  line-height: 1.5;
  margin: 0;
`;
const body2Css = css`
  color: #262626;
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, Open Sans, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 400;
  line-height: 1.43;
  margin: 0;
`;
const buttonCss = css`
  color: #fff;
  background-color: #004650;
  white-space: nowrap;
  padding: 9px 16px;
  font-size: 0.875rem;
  min-width: 64px;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: -apple-system, BlinkMacSystemFont, Open Sans, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 600;
  line-height: 1.75;
  border-radius: 4px;
  text-transform: none;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  position: relative;
  align-items: center;
  user-select: none;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none !important;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  &:hover {
    background-color: rgb(25, 88, 97);
  }
  & > span {
    width: 100%;
    display: inherit;
    align-items: inherit;
    font-weight: 600;
    letter-spacing: 0.0367rem;
    justify-content: inherit;
  }
`;
export const SmallButton = styled.a`
  ${buttonCss}
  padding: 4px 10px;
  font-size: 0.8125rem;
`;
export const H6 = styled.h6`
  color: #262626;
  font-size: 1.125rem;
  font-family: -apple-system, BlinkMacSystemFont, Open Sans, Sans-Serif;
  font-weight: 600;
  line-height: 1.6;
  margin: 0;
`;
export const H2 = styled.h2`
  color: #262626;
  font-size: 1.4583rem;
  font-family: -apple-system, BlinkMacSystemFont, Open Sans, Sans-Serif;
  font-weight: 600;
  line-height: 1.2;
  margin: 0;
`;
export const Typography = styled.span`
  ${body1Css}
`;
export const SmallTypography = styled.span`
  ${body2Css}
`;
export const Subtitle2 = styled.span`
  color: #898989;
  font-size: 0.875rem;
  font-family: -apple-system, BlinkMacSystemFont, Open Sans, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 400;
  line-height: 1.57;
`;
export const BrandTypography = styled.a`
  ${body1Css}
  color: #4da1b8;
  font-family: 'Varela Round';
  font-size: 1.2rem;
  font-weight: 600;
  text-decoration: none;
`;
export const Caption = styled.span`
  color: #262626;
  font-size: 0.75rem;
  font-family: -apple-system, BlinkMacSystemFont, Open Sans, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  font-weight: 400;
  line-height: 1.66;
  margin: 0;
`;

export const SmallLink = styled.a`
  ${body2Css}
  color: #666;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    text-decoration: none;
  }
`;
export const HR = styled.hr`
  border: none;
  height: 1px;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.12);
  margin: 0;
`;

export const Center = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  }
`;
export const mainContainerCss = css`
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 4px;
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
`;
export const EventMainContainer = styled.div`
  min-height: 400px;
  ${mainContainerCss}
`;
export const CoachMainContainer = styled.div`
  min-height: 300px;
  ${mainContainerCss}
`;

export const SliderPreviewImages = styled.div`
  @charset 'UTF-8';
  & .slick-loading .slick-list {
    background: #fff url('../fonts/ajax-loader.gif') center center no-repeat;
  }

  @font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;

    src: url('../fonts/slick.eot');
    src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'),
      url('../fonts/slick.woff') format('woff'), url('../fonts/slick.ttf') format('truetype'),
      url('../fonts/slick.svg#slick') format('svg');
  }
  & .slick-prev,
  & .slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
  }
  & .slick-prev:hover,
  & .slick-prev:focus,
  & .slick-next:hover,
  & .slick-next:focus {
    color: transparent;
    outline: none;
    background: transparent;
  }
  & .slick-prev:hover:before,
  & .slick-prev:focus:before,
  & .slick-next:hover:before,
  & .slick-next:focus:before {
    opacity: 1;
  }
  & .slick-prev.slick-disabled:before,
  & .slick-next.slick-disabled:before {
    opacity: 0.25;
  }

  & .slick-prev:before,
  & .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;

    opacity: 0.75;
    color: white;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  & .slick-prev {
    left: -25px;
  }
  [dir='rtl'] .slick-prev {
    right: -25px;
    left: auto;
  }
  & .slick-prev:before {
    content: '←';
  }
  [dir='rtl'] .slick-prev:before {
    content: '→';
  }

  & .slick-next {
    right: -25px;
  }
  [dir='rtl'] .slick-next {
    right: auto;
    left: -25px;
  }
  & .slick-next:before {
    content: '→';
  }
  [dir='rtl'] .slick-next:before {
    content: '←';
  }

  & .slick-dotted.slick-slider {
    margin-bottom: 30px;
  }

  & .slick-dots {
    position: absolute;
    bottom: -25px;

    display: block;

    width: 100%;
    padding: 0;
    margin: 0;

    list-style: none;

    text-align: center;
  }
  & .slick-dots li {
    position: relative;

    display: inline-block;

    width: 20px;
    height: 20px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
  }
  & .slick-dots li button {
    font-size: 0;
    line-height: 0;

    display: block;

    width: 20px;
    height: 20px;
    padding: 5px;

    cursor: pointer;

    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  & .slick-dots li button:hover,
  & .slick-dots li button:focus {
    outline: none;
  }
  & .slick-dots li button:hover:before,
  & .slick-dots li button:focus:before {
    opacity: 1;
  }
  & .slick-dots li button:before {
    font-family: 'slick';
    font-size: 6px;
    line-height: 20px;

    position: absolute;
    top: 0;
    left: 0;

    width: 20px;
    height: 20px;

    content: '•';
    text-align: center;

    opacity: 0.25;
    color: black;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  & .slick-dots li.slick-active button:before {
    opacity: 0.75;
    color: black;
  }
  & .slick-slider {
    position: relative;

    display: block;
    box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }

  & .slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
  }
  & .slick-list:focus {
    outline: none;
  }
  & .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }

  & .slick-slider .slick-track,
  & .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  & .slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  & .slick-track:before,
  & .slick-track:after {
    display: table;

    content: '';
  }
  & .slick-track:after {
    clear: both;
  }
  & .slick-loading .slick-track {
    visibility: hidden;
  }

  & .slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  & .slick-slide img {
    display: block;
  }
  & .slick-slide.slick-loading img {
    display: none;
  }
  & .slick-slide.dragging img {
    pointer-events: none;
  }
  & .slick-initialized .slick-slide {
    display: block;
  }
  & .slick-loading .slick-slide {
    visibility: hidden;
  }
  & .slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
  & .slick-arrow.slick-hidden {
    display: none;
  }

  & .slick-dots li button:before {
    color: white;
  }
  & .slick-dots li.slick-active button:before {
    color: white;
  }
  & .slick-prev,
  .slick-next {
    height: 36px;
    width: 36px;
  }
  & .slick-prev:before,
  .slick-next:before {
    font-size: 24px;
  }
  & .slick-prev {
    left: 12px;
    z-index: 1;
  }
  & .slick-next {
    right: 12px;
  }
  & .slick-dots {
    bottom: 8px;
  }
`;

import { format as tzFormat, utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';
import { sort } from 'ramda';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
import isEqual from 'date-fns/isEqual';
import isSameYear from 'date-fns/isSameYear';
import isSameMonth from 'date-fns/isSameMonth';
import setHours from 'date-fns/setHours';
import setMinutes from 'date-fns/setMinutes';
import setSeconds from 'date-fns/setSeconds';
import setMilliseconds from 'date-fns/setMilliseconds';
import { EventType } from './queries';
import { add } from 'date-fns';
import * as R from 'ramda';
export const DOMAIN = 'https://www.goreggy.com/';

/*****************************************************
 * Coach
 *****************************************************/

/**
 * Get the user name
 */
export const getUserFullName = (
  user: { firstName: string | null; lastName: string | null } | null | undefined,
  options?: { shortenLastName: boolean },
): string | null => {
  if (!user) {
    return null;
  }
  if (!user.firstName && !user.lastName) {
    return null;
  }
  if (user.firstName && !user.lastName) {
    return user.firstName;
  }
  if (!user.firstName && user.lastName) {
    return user.lastName;
  }
  if (options?.shortenLastName) {
    return `${user.firstName ?? ''} ${user.lastName?.substring(0, 1) ?? ''}.`;
  }
  return `${user.firstName ?? ''} ${user.lastName ?? ''}`;
};

export const correctlyTypeStringArray = (stringArray: string[] | unknown): string[] => {
  const stringArrayUnknown = stringArray as string[] | string;
  let typedStringArray: string[];
  if (typeof stringArrayUnknown === 'string') {
    typedStringArray = JSON.parse(stringArrayUnknown) as string[];
  } else {
    typedStringArray = stringArrayUnknown;
  }
  return typedStringArray;
};
/**
 * Convert a coach location to readable text
 */
export const getCoachLocationText = (
  coachLocation:
    | {
        city: string | undefined | null;
        state: string | undefined | null;
        country: string;
        countryCode: string | undefined | null;
      }
    | undefined,
): string => {
  if (!coachLocation) {
    return '';
  }
  return `${coachLocation?.city ? `${coachLocation.city}, ` : ''}${
    coachLocation?.state ? `${coachLocation.state}, ` : ''
  }${coachLocation.countryCode || coachLocation.country}`;
};
/**
 * Summary of coach
 */
export const getCoachingSummary = (coachData: {
  coachingVibe: string[] | undefined;
  coachDisciplines: { name: string }[] | readonly { readonly name: string }[] | undefined;
}): string | null => {
  // Get coaching vibe
  const coachingVibe = correctlyTypeStringArray(coachData?.coachingVibe);
  const readableCoachingVibe = coachingVibe?.length
    ? `${coachingVibe[0]} & ${coachingVibe[1].toLowerCase()}`
    : '';

  // Get disciplines
  const hasDisciplines = !!coachData?.coachDisciplines?.length;
  if (readableCoachingVibe && !hasDisciplines) {
    return `${readableCoachingVibe} coach`;
  }
  if (!readableCoachingVibe && hasDisciplines) {
    return `Coach who teaches ${arrayToCommaAndString(
      (coachData?.coachDisciplines ?? []).map(R.prop('name')),
    )}`;
  }
  if (readableCoachingVibe && hasDisciplines) {
    return `${readableCoachingVibe} coach who teaches ${arrayToCommaAndString(
      (coachData?.coachDisciplines ?? []).map(R.prop('name')),
    )}`;
  }
  return '';
};

/**
 * Get the price of a lesson
 */
export const getLessonPrice = (
  lesson:
    | {
        participantRange: number[];
        price: number | null;
      }
    | null
    | undefined,
  currencyCode: string,
): string => {
  if (!lesson?.price) {
    return 'Free';
  }
  return `${formatNumberAsCurrency(lesson.price, currencyCode)} / ${getParticipantTypeText(
    lesson.participantRange,
  )}`;
};
/**
 * We need to change a range [1-2] to a person or a group
 */
export const getParticipantTypeText = (participantRange: number[]): string => {
  return `person${
    R.equals(participantRange, [1, 1])
      ? ''
      : `, group of ${participantRange[0]}-${participantRange[1]}`
  }`;
};
/*****************************************************
 * Events
 *****************************************************/
/**
 * Returns a string representing the event's city, state, and country.
 * Or returns 'Virtual Event' if the virtual event flag is detected.
 */
export function getEventLocation(ev: EventType): string | null {
  if (ev.eventVenues?.length > 0) {
    const { venue } = ev.eventVenues[0];
    if (venue) {
      const { city, country, state } = venue;
      return [city, state, country].reduce(
        (loc, part) => (loc && part ? `${loc}, ${part}` : loc || part || ''),
        '',
      );
    }
  }
  if (ev.isVirtualEvent) {
    return 'Virtual Event';
  }
  return null;
}

/**
 * Get event name from event metadata and occurrence label if it exists
 */
export const getEventName = (
  event:
    | {
        eventMetadata: { name: string } | null;
        occurrenceLabel?: string | null | undefined;
      }
    | null
    | undefined,
): string => {
  if (!event) {
    return '';
  }
  const { eventMetadata, occurrenceLabel } = event;
  if (!eventMetadata) {
    return '';
  }
  if (!occurrenceLabel) {
    return eventMetadata.name;
  }
  return `${eventMetadata.name} - ${occurrenceLabel}`;
};

export const buildClinicDays = (
  clinic: EventType['clinics'][0],
): { dayText: string; timeRange: string }[] => {
  const { clinicDays } = clinic;
  return clinicDays.map((clinicDay, index) => {
    // Build time
    const startTime = dateFromFloatingDateString(clinicDay.startTime);
    const endTime = add(startTime, {
      minutes: clinicDay.eventClinicDayDuration.duration,
    });
    const timeRange = getTimeRange(startTime, endTime);

    // Build day
    let dayText = format(startTime, 'EEE, MMM dd, yyyy');
    if (new Date().getFullYear() === startTime.getFullYear()) {
      dayText = format(startTime, 'EEE, MMM dd');
    }
    if (clinicDays.length > 1) {
      dayText = `Day ${index + 1}: ${dayText}`;
    }
    return { dayText, timeRange };
  });
};

/*****************************************************
 * Dates
 *****************************************************/
/**
 * Get a time range from two dates
 * 7:00 AM - 8:00 AM
 */
export const getTimeRange = (startDate: Date, endDate: Date, shouldUse12Hour = true): string => {
  return `${startDate.toLocaleTimeString([], {
    hour: shouldUse12Hour ? 'numeric' : '2-digit',
    minute: '2-digit',
    hour12: shouldUse12Hour,
  })} - ${endDate.toLocaleTimeString([], {
    hour: shouldUse12Hour ? 'numeric' : '2-digit',
    minute: '2-digit',
    hour12: shouldUse12Hour,
  })}`;
};

/**
 * Gets timezone abbreviation from timezone e.g. America/Los_Angeles -> PST
 */
export const getTimezoneAbbreviation = (timezone: string): string => {
  return tzFormat(utcToZonedTime(new Date(), timezone), 'zzz', { timeZone: timezone });
};

/**
 * Gets timezone  e.g. America/Los_Angeles
 */
export const getCurrentTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

/**
 * Date from Floating Date String
 * Takes a ISO like string without the timezone and converts it into a JS Date object with that time
 */
export const dateFromFloatingDateString = (dateString: string): Date => {
  return initializeDateToTimezone(
    parseISO(dateString),
    Intl.DateTimeFormat().resolvedOptions().timeZone,
  );
};

/**
 * Convert date to time
 * Date object to 12:10 PM or 12:10
 */
export const convertDateToTimeString = (date: Date, shouldUse12Hour = true): string => {
  if (shouldUse12Hour) {
    return date.toLocaleTimeString([], { hour: 'numeric', minute: '2-digit', hour12: true });
  }
  // 24 hour time
  const hours = date.getHours();
  const minutes = date.getMinutes();
  return `${hours >= 10 ? hours : `0${hours}`}:${minutes >= 10 ? minutes : `0${minutes}`}`;
};

export const getEventTimezone = (event: EventType): string => {
  let tz = getCurrentTimezone();
  if (event.isVirtualEvent && event.eventVirtualVenues && event.eventVirtualVenues.length > 0) {
    tz = event.eventVirtualVenues[0].timezone;
  } else if (event.eventVenues && event.eventVenues.length > 0 && event.eventVenues[0].venue) {
    tz = event.eventVenues[0].venue.timezone;
  }
  return tz;
};
/**
 * Initialize date in a specific timezone
 */
export const initializeDateToTimezone = (
  date: Date | string,
  tzString = getCurrentTimezone(),
): Date => {
  return zonedTimeToUtc(date, tzString);
};

/**
 * Set a Date to midnight
 */
export function setTimeToMidnight(date: Date): Date {
  return setHours(setMinutes(setSeconds(setMilliseconds(date, 0), 0), 0), 0);
}

/**
 * Feb 6, 2019 - Feb 6, 2019 formatted as Feb 6, 2019
 * Feb 6, 2019 - Feb 8, 2019 formatted as Feb 6 - 8, 2019
 * Feb 6, 2019 - Mar 8, 2019 formatted as Feb 6 - Mar 8, 2019
 * Feb 20, 2019 - Mar 3, 2020 formatted as Feb 20, 2019 - Mar 3, 2020
 */
export function readableDateRange(
  startDate: Date | string | null | undefined,
  endDate: Date | string | null | undefined,
): string {
  if (!startDate && !endDate) {
    return '';
  }
  const startDateMidnight =
    typeof startDate === 'string' ? parseISO(startDate) : (startDate as Date);
  const endDateMidnight = typeof endDate === 'string' ? parseISO(endDate) : (endDate as Date);

  if (startDate && !endDate) {
    return format(startDateMidnight, 'MMM d, y');
  }
  if (!startDate && endDate) {
    return format(endDateMidnight, 'MMM d, y');
  }

  if (!isEqual(startDateMidnight, endDateMidnight)) {
    if (isSameYear(startDateMidnight, endDateMidnight)) {
      if (isSameMonth(startDateMidnight, endDateMidnight)) {
        // Different day, use same month
        return `${format(startDateMidnight, 'MMM d')} - ${format(endDateMidnight, 'd, y')}`;
      }
      // Different months, use same year
      return `${format(startDateMidnight, 'MMM d')} - ${format(endDateMidnight, 'MMM d, y')}`;
    }
    // Different years, use full date
    return `${format(startDateMidnight, 'MMM d, y')} - ${format(endDateMidnight, 'MMM d, y')}`;
  }
  // Both dates are the same
  return format(startDateMidnight, 'MMM d, y');
}

/*****************************************************
 * Objects
 *****************************************************/
/**
 * Sort an array asc
 */
export function sortArrayAsc(arrayToSort: number[]): number[] {
  const diff = (a: number, b: number): number => {
    return a - b;
  };
  return sort(diff, arrayToSort);
}

/*****************************************************
 * Strings
 *****************************************************/
// ['apple'] = 'apple'
// ['apple', 'pear'] = 'apple and pear'
// ['apple', 'pear', 'orange'] = 'apple, pear, and orange'
export const arrayToCommaString = (
  arrayToConvert: (string | number)[],
  connectingWord?: string,
): string => {
  if (!R.length(arrayToConvert)) {
    return '';
  }
  if (R.length(arrayToConvert) === 1) {
    return String(arrayToConvert[0]);
  }
  if (R.length(arrayToConvert) === 2) {
    return `${arrayToConvert[0]} ${connectingWord ? `${connectingWord} ` : ''}${arrayToConvert[1]}`;
  }
  return `${R.join(', ', R.init(arrayToConvert))}, ${connectingWord ? `${connectingWord} ` : ''}${
    R.last(arrayToConvert) as string
  }`;
};
export const arrayToCommaAndString = (arrayToConvert: (string | number)[]): string =>
  arrayToCommaString(arrayToConvert, 'and');

/*****************************************************
 * Numbers
 *****************************************************/
export function formatNumberAsCurrency(numberToFormat: number, currencyCode: string): string {
  const formatter = new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
  return formatter.format(numberToFormat); /* $2,500.00 */
}

/*****************************************************
 * Stripe
 *****************************************************/
/**
 * Does a stripe account have charges and payouts enabled
 */
export const getIsStripeConnectAccountValid = (
  stripeConnectAccount: { payoutsEnabled: boolean; chargesEnabled: boolean } | undefined | null,
): boolean => {
  if (!stripeConnectAccount) {
    return false;
  }
  return stripeConnectAccount?.payoutsEnabled && stripeConnectAccount?.chargesEnabled;
};

import axios from 'axios';

export type EventType = {
  readonly name: string;
  readonly cuid: string;
  readonly slug: string;
  readonly activity: string;
  readonly currencyCode: string;
  readonly nonCategoriedRegDeadline: number | null;
  readonly startDate: string | null;
  readonly endDate: string | null;
  readonly regCloseDate: string | null;
  readonly isVirtualEvent: boolean;
  readonly eventMetadata: {
    readonly name: string;
    readonly isCategoriedEvent: boolean;
    readonly slug: string;
  };
  readonly eventDisciplines: ReadonlyArray<{
    readonly disciplineName: string;
  }>;
  readonly eventImages: ReadonlyArray<{
    readonly image: {
      readonly url: string;
    };
  }>;
  readonly categories: ReadonlyArray<{
    readonly cuid: string;
    readonly name: string;
    readonly entryFee: number | null;
    readonly eventDiscipline: {
      readonly disciplineName: string;
    };
  }>;
  readonly eventTickets: ReadonlyArray<{
    readonly cuid: string;
    readonly name: string;
    readonly price: number | null;
  }>;
  readonly eventVenues: ReadonlyArray<{
    readonly venue: {
      readonly city: string | null;
      readonly state: string | null;
      readonly country: string | null;
      readonly timezone: string;
    };
  }>;
  readonly eventVirtualVenues: ReadonlyArray<{
    readonly timezone: string;
  }>;
  readonly organizer: {
    readonly name: string;
    readonly slug: string;
  };
  readonly eventPrograms: ReadonlyArray<{
    readonly defaultPrice: number | null;
  }>;
  readonly clinics: ReadonlyArray<{
    readonly cuid: string;
    readonly priceOverride: number | null;
    readonly spotsRemaining: number | null;
    readonly clinicDays: ReadonlyArray<{
      readonly cuid: string;
      readonly startTime: string;
      readonly eventClinicDayDuration: {
        readonly dayNumber: number;
        readonly duration: number;
      };
    }>;
  }>;
  readonly rides: ReadonlyArray<{
    readonly cuid: string;
    readonly priceOverride: number | null;
    readonly spotsRemaining: number | null;
    readonly rideDays: ReadonlyArray<{
      readonly startTime: string;
    }>;
  }>;
};

export async function getEvent(eventCuid: string): Promise<EventType> {
  const data = await axios.post('https://reggy.hasura.app/v1beta1/relay', {
    variables: { eventCuid },
    query: `
      query getEventForEmbed($eventCuid: String!) {
        event_connection(where: {_and: [{canceledAt: {_is_null: true}}, {deleted_at: {_is_null: true}}, {publishedAt: {_is_null: false}}, {cuid: {_eq: $eventCuid}}]}) {
          edges {
            node {
              cuid
              activity
              currencyCode
              regOpenDate
              regCloseDate
              startDate
              endDate
              nonCategoriedRegDeadline
              isVirtualEvent
              occurrenceLabel
              eventDisciplines {
                disciplineName
              }
              eventMetadata {
                slug
                isCategoriedEvent
                name
              }
              eventImages {
                image {
                  url
                }
              }
              categories(
                where: {
                  _and: [
                    { deleted_at: { _is_null: true } }
                    { visible: { _eq: true } }
                    { _or: [{ spotsRemaining: { _is_null: true } }, { spotsRemaining: { _gt: 0 } }] }
                  ]
                }
                order_by: [{ eventDiscipline: { disciplineName: asc } }, { position: asc }]
              ) {
                cuid
                name
                entryFee
                eventDiscipline {
                  disciplineName
                }
              }
              eventTickets {
                cuid
                name
                price
              }
              eventVenues {
                venue {
                  city
                  state
                  country
                  timezone
                }
              }
              eventVirtualVenues {
                timezone
              }
              organizer {
                name
                slug
              }
              eventPrograms {
                defaultPrice
              }
              rides(where: {canceledAt: {_is_null: true}, registrationStatus: {_eq: "Open"}, _or: [{spotsRemaining: {_is_null: true}}, {spotsRemaining: {_gt: 0}}]}) {
                cuid
                priceOverride
                spotsAvailable
                spotsRemaining
                registrationStatus
                rideDays {
                  startTime
                }
              }
              clinics(where: {canceledAt: {_is_null: true}, registrationStatus: {_eq: "Open"}, _or: [{spotsRemaining: {_is_null: true}}, {spotsRemaining: {_gt: 0}}]}) {
                cuid
                priceOverride
                spotsAvailable
                spotsRemaining
                meetingLink
                registrationStatus
                clinicDays {
                  cuid
                  startTime
                  eventClinicDayDuration {
                    dayNumber
                    duration
                  }
                }
              }
            }
          }
        }
      }
      `,
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return (data.data?.data?.event_connection?.edges?.[0]?.node ?? null) as never;
}

export type CoachType = {
  readonly firstName: string;
  readonly lastName: string;
  readonly profilePicUrl: string;
  readonly pronouns: string;
  readonly slug: string;
  readonly stripeIdentityVerificationStatus: string;
  readonly stripeConnectAccount: {
    readonly chargesEnabled: boolean;
    readonly payoutsEnabled: boolean;
  } | null;
  readonly coach: {
    readonly allowRequests: boolean;
    readonly coachingVibe: string[];
    readonly organizerCuidToDisplay: string;
    readonly currencyCode: string;
    readonly coachLocations: ReadonlyArray<{
      readonly city: string | null;
      readonly state: string | null;
      readonly countryCode: string | null;
      readonly country: string;
    }>;
    readonly coachDisciplines: ReadonlyArray<{
      readonly name: string;
    }>;
    readonly organizer: {
      readonly name: string;
      readonly slug: string;
    } | null;
    readonly coachLessons: ReadonlyArray<{
      readonly cuid: string;
      readonly title: string;
      readonly participantRange: number[];
      readonly price: number | null;
    }>;
  };
};
export async function getCoach(coachCuid: string): Promise<CoachType> {
  const data = await axios.post('https://reggy.hasura.app/v1beta1/relay', {
    variables: { coachCuid },
    query: `
      query getCoachForEmbed($coachCuid: String!) {
        user_connection(where: {coach: {cuid: {_eq: $coachCuid}}}) {
          edges {
            node {
              firstName
              lastName
              profilePicUrl
              pronouns
              stripeIdentityVerificationStatus
              slug
              stripeConnectAccount {
                chargesEnabled
                payoutsEnabled
              }
              coach {
                allowRequests
                coachingVibe
                organizerCuidToDisplay
                currencyCode
                coachLocations {
                  city
                  state
                  countryCode
                  country
                }
                coachDisciplines {
                  name
                }
                organizer {
                  name
                  slug
                }
                coachLessons(where: { isHidden: { _eq: false } }) {
                  cuid
                  title
                  price
                  participantRange
                }
              }
            }
          }
        }
      }
    `,
  });
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  return (data.data?.data?.user_connection?.edges?.[0]?.node ?? null) as never;
}

import React, { useMemo } from 'react';

import { SmallButton, Subtitle2 } from './Styles';
import ClinicItem from './ClinicItem';
import {
  dateFromFloatingDateString,
  DOMAIN,
  formatNumberAsCurrency,
  getEventTimezone,
  initializeDateToTimezone,
} from '../lib/utils';
import { compareAsc, isAfter, isBefore, subSeconds } from 'date-fns';

import { EventType } from '../lib/queries';
import * as R from 'ramda';
import RideItem from './RideItem';
import styled from 'styled-components';

/**
 * Sort a clinic by first clinic day's start date
 */
export const sortClinicsByFirstStartDateAsc = (
  firstClinic: EventType['clinics'][0],
  secondClinic: EventType['clinics'][0],
): number =>
  compareAsc(
    dateFromFloatingDateString(firstClinic.clinicDays?.[0].startTime),
    dateFromFloatingDateString(secondClinic.clinicDays?.[0].startTime),
  );
/**
 * Sort a ride by first ride day's start date
 */
export const sortRidesByFirstStartDateAsc = (
  firstRide: EventType['rides'][0],
  secondRide: EventType['rides'][0],
): number =>
  compareAsc(
    dateFromFloatingDateString(firstRide.rideDays?.[0].startTime),
    dateFromFloatingDateString(secondRide.rideDays?.[0].startTime),
  );
/**
 * Is clinic or ride past the registration deadline?
 */
export const getIsTodayBeforeRegDeadline = (
  instance: EventType['clinics'][0] | EventType['rides'][0],
  timezone: string,
  nonCategoriedRegDeadline: number | null,
): boolean => {
  const startTime =
    'clinicDays' in instance
      ? instance.clinicDays?.[0].startTime
      : instance.rideDays?.[0].startTime;
  if (!startTime) {
    return false;
  }
  return isBefore(
    new Date(),
    subSeconds(
      initializeDateToTimezone(dateFromFloatingDateString(startTime), timezone),
      nonCategoriedRegDeadline || 0,
    ),
  );
};
/**
 * Sort clinics and ride instances that are open first
 */
type Instance = EventType['clinics'][0] | EventType['rides'][0];
export const sortOpenInstancesFirst = (event: EventType): ((list: Instance[]) => Instance[]) =>
  R.sortBy(
    (instance: EventType['clinics'][0] | EventType['rides'][0]) =>
      !getIsTodayBeforeRegDeadline(
        instance,
        getEventTimezone(event),
        event.nonCategoriedRegDeadline,
      ),
  );

export const InstanceContainer = styled.div`
  display: flex;
  padding: 4px 0;
  border-bottom: 1px solid #e0e0e0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 8px;
    width: 50%;
    @media (max-width: 768px) {
      width: auto;
    }
  }
  }
`;

const InstancesContainer: React.FC<{ event: EventType }> = ({ event }) => {
  const { clinics, rides, currencyCode, eventPrograms, activity } = event;
  const isClinic = activity === 'Clinic';
  const upcomingInstances = useMemo<Instance[]>(() => {
    if (isClinic) {
      return sortOpenInstancesFirst(event)(
        clinics
          .filter((clinic) => {
            const startTime = clinic.clinicDays?.[0].startTime;
            if (!startTime) {
              return false;
            }
            const parsedStartTime = dateFromFloatingDateString(startTime);
            return isAfter(parsedStartTime, new Date());
          })
          .sort(sortClinicsByFirstStartDateAsc),
      ).slice(0, 3);
    }
    return sortOpenInstancesFirst(event)(
      rides
        .filter((ride) => {
          const startTime = ride.rideDays?.[0].startTime;
          if (!startTime) {
            return false;
          }
          const parsedStartTime = dateFromFloatingDateString(startTime);
          return isAfter(parsedStartTime, new Date());
        })
        .sort(sortRidesByFirstStartDateAsc),
    ).slice(0, 3);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const hasInstances = Boolean(upcomingInstances?.length);
  const showSeeMoreDates = upcomingInstances.length > 3;

  const eventLink = `${DOMAIN}org/${event.organizer.slug}/events/${event.slug}`;

  return (
    <>
      {hasInstances ? (
        <>
          {upcomingInstances.map((instance) => {
            // Prices
            let priceWithCurrency = 'Free';
            if (isClinic) {
              const { defaultPrice } = eventPrograms[0];
              const price = instance.priceOverride || defaultPrice;
              if (price) {
                priceWithCurrency = formatNumberAsCurrency(price, currencyCode);
              }
              return (
                <ClinicItem
                  event={event}
                  priceWithCurrency={priceWithCurrency}
                  clinic={instance as EventType['clinics'][0]}
                  key={instance.cuid}
                />
              );
            } else {
              if (instance.priceOverride) {
                formatNumberAsCurrency(instance.priceOverride, event.currencyCode);
              }
              return (
                <RideItem
                  event={event}
                  priceWithCurrency={priceWithCurrency}
                  ride={instance as EventType['rides'][0]}
                  key={instance.cuid}
                />
              );
            }
          })}
        </>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginTop: '12px',
            marginBottom: '4px',
          }}
        >
          <Subtitle2>No upcoming {isClinic ? 'clinic' : 'ride'}s scheduled</Subtitle2>
        </div>
      )}
      {showSeeMoreDates && (
        <div
          style={{
            borderBottom: '1px solid #e0e0e0',
            margin: '0 auto',
            display: 'flex',
            width: '100%',
            padding: '16px 0',
          }}
        >
          <SmallButton
            target={'_blank'}
            rel={'noopener nofollow noreferrer'}
            href={eventLink}
            style={{ flexGrow: 1 }}
          >
            See More Dates
          </SmallButton>
        </div>
      )}
    </>
  );
};

export default InstancesContainer;

import React from 'react';
import styled from 'styled-components';
import {
  BrandTypography,
  Center,
  H6,
  HR,
  SliderPreviewImages,
  SmallLink,
  SmallTypography,
  Typography,
} from './Styles';
import InstancesContainer from './InstancesContainer';
import { EventType, getEvent } from '../lib/queries';
import { useQuery } from 'react-query';
import { parseISO } from 'date-fns';
import {
  arrayToCommaAndString,
  DOMAIN,
  getEventLocation,
  getEventName,
  readableDateRange,
} from '../lib/utils';
import RacesContainer from './RacesContainer';
import { Loader } from '../App';
import Slider from 'react-slick';

const ImageContainer = styled.div`
  display: flex;
  align-items: flex-start;
  overflow: hidden;
  height: 232px;
  & > img {
    height: 100%;
    object-fit: cover;
    width: 100%;
    transition: transform 0.45s cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
    will-change: transform;
    &:hover {
      transform: scale(1.04);
    }
  }
`;
const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  flex: 1;
`;

const reactSlickImageSettings = {
  lazyLoad: 'ondemand' as const,
  dots: true,
  infinite: true,
  speed: 200,
  slidesToShow: 1,
  slidesToScroll: 1,
};
export const DEFAULT_EVENT_IMAGE =
  'https://res.cloudinary.com/goreggy/image/upload/v1605494962/egooenxaqt8hdubxhx3l.svg';

const EventEmbed: React.FC<{ cuid: string }> = ({ cuid }) => {
  const { data: eventData, isFetching } = useQuery<EventType>(cuid, () => getEvent(cuid));
  if (!eventData) {
    if (!eventData && !isFetching) {
      return (
        <Center style={{ flexDirection: 'column' }}>
          <Typography>No event found</Typography>
          <SmallLink
            target={'_blank'}
            rel={'noopener nofollow noreferrer'}
            href={'https://www.goreggy.com'}
          >
            Go to Reggy
          </SmallLink>
        </Center>
      );
    }
    return <Loader />;
  }
  const isCategoriedEvent = eventData.eventMetadata.isCategoriedEvent;
  const isClinic = eventData.activity === 'Clinic';
  const isNonCategoriedRide =
    eventData.activity === 'Ride' && !eventData.eventMetadata.isCategoriedEvent;

  const imageUrls: string[] = eventData.eventImages.length
    ? eventData.eventImages.map(({ image }) => image.url)
    : [DEFAULT_EVENT_IMAGE];

  // Data
  let dateRange = '';
  if (isCategoriedEvent && eventData.startDate && eventData.endDate) {
    dateRange = readableDateRange(parseISO(eventData.startDate), parseISO(eventData.endDate));
  }

  // Location
  const eventLocation = getEventLocation(eventData);

  // Link
  const organizerLink = `${DOMAIN}org/${eventData.organizer.slug}`;
  const eventLink = `${DOMAIN}org/${eventData.organizer.slug}/events/${eventData.eventMetadata.slug}?occurrence=${eventData.cuid}`;

  return (
    <>
      <a target={'_blank'} rel={'noopener nofollow noreferrer'} href={eventLink}>
        <div style={{ width: '100%' }}>
          <SliderPreviewImages>
            <Slider {...reactSlickImageSettings}>
              {(imageUrls ?? []).map((imageUrl) => (
                <ImageContainer key={imageUrl}>
                  <img alt="Event Preview" src={imageUrl} />
                </ImageContainer>
              ))}
            </Slider>
          </SliderPreviewImages>
        </div>
      </a>
      <ContentContainer>
        <H6>{getEventName(eventData)}</H6>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <SmallTypography>
            {arrayToCommaAndString(eventData.eventDisciplines.map((ed) => ed.disciplineName))}{' '}
            {eventData.activity}
          </SmallTypography>
          <SmallTypography>{dateRange}</SmallTypography>
          <SmallTypography>{eventLocation}</SmallTypography>
          <SmallLink target={'_blank'} rel={'noopener nofollow noreferrer'} href={organizerLink}>
            {eventData.organizer.name}
          </SmallLink>
        </div>
        <div
          style={
            isClinic || isNonCategoriedRide
              ? { marginBottom: '-4px', padding: '8px 0' }
              : { padding: '8px 0' }
          }
        >
          <HR />
        </div>
        {(isClinic || isNonCategoriedRide) && <InstancesContainer event={eventData} />}
        {isCategoriedEvent && <RacesContainer event={eventData} />}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: '8px',
          }}
        >
          <SmallLink target={'_blank'} rel={'noopener nofollow noreferrer'} href={eventLink}>
            View on Reggy
          </SmallLink>
          <BrandTypography
            target={'_blank'}
            rel={'noopener nofollow noreferrer'}
            href="https://www.goreggy.com"
          >
            Reggy
          </BrandTypography>
        </div>
      </ContentContainer>
    </>
  );
};

export default EventEmbed;

import React, { useState } from 'react';

import { SmallButton, Typography } from './Styles';
import { dateFromFloatingDateString, DOMAIN, formatNumberAsCurrency } from '../lib/utils';
import { compareAsc, isAfter, parseISO } from 'date-fns';

import { reject, uniq } from 'ramda';
import { EventType } from '../lib/queries';
import Select from 'react-select';
import { SingleValue } from 'react-select';
import styled from 'styled-components';

const IconButton = styled.button`
  color: inherit;
  border: 0;
  cursor: pointer;
  margin: 0;
  display: inline-flex;
  outline: 0;
  padding: 0;
  position: relative;
  align-items: center;
  user-select: none;
  border-radius: 0;
  vertical-align: middle;
  -moz-appearance: none;
  justify-content: center;
  text-decoration: none;
  background-color: transparent;
  -webkit-appearance: none;
  -webkit-tap-highlight-color: transparent;
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  padding: 12px;
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
  padding: 3px;
  font-size: 1.125rem;
  &:hover {
    background-color: rgba(0, 0, 0, 0.04);
  }
`;

const SVGIcon = styled.svg`
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  flex-shrink: 0;
  user-select: none;
  font-size: 1.125rem;
  color: #c8c8c8;
`;

/**
 * Sort a clinic by first clinic day's start date
 */
export const sortClinicsByFirstStartDateAsc = (
  firstClinic: EventType['clinics'][0],
  secondClinic: EventType['clinics'][0],
): number =>
  compareAsc(
    dateFromFloatingDateString(firstClinic.clinicDays?.[0].startTime),
    dateFromFloatingDateString(secondClinic.clinicDays?.[0].startTime),
  );

const RacesContainer: React.FC<{ event: EventType }> = ({ event }) => {
  const { regCloseDate, categories, eventDisciplines } = event;

  const [selectedCuids, setSelectedCuids] = useState<string[]>([]);

  // Registration Dates
  const isRegistrationOpen = regCloseDate && isAfter(parseISO(regCloseDate), new Date());

  // Remove all the currently selected categories from the dropdown
  const filteredCategories = (categories ?? [])
    .filter((cat) => {
      return !selectedCuids.includes(cat.cuid);
    })
    .map((cat) => {
      return {
        ...cat,
        value: cat.cuid,
        label:
          eventDisciplines.length > 1
            ? cat.eventDiscipline.disciplineName + ' - ' + cat.name
            : cat.name,
      };
    });

  // Currently, selected categories. Either from dropdown or categories section
  const selectedCategories = (categories ?? []).filter((cat) => {
    return selectedCuids.includes(cat.cuid);
  });

  // Registration Link
  const registerLink = `${DOMAIN}org/${event.organizer.slug}/events/${
    event.eventMetadata.slug
  }/register?occurrence=${event.cuid}${
    selectedCuids.length > 0 ? `&categories=${selectedCuids.join(',')}` : ''
  }`;

  /**
   * Add a new category to the registration card
   */
  const handleAddCategoryChange = (
    categoryToAdd: SingleValue<
      { readonly cuid: string; readonly name: string; readonly entryFee: unknown } | undefined
    >,
  ): void => {
    if (!categoryToAdd) {
      return;
    }
    setSelectedCuids((currentlySelected) => uniq([...currentlySelected, categoryToAdd.cuid]));
  };

  /**
   * Remove a category from the registration card
   */
  const handleRemoveCategory = (categoryCuidToRemove: string): void => {
    setSelectedCuids((currentlySelected) => {
      return reject((catCuid) => catCuid === categoryCuidToRemove, currentlySelected);
    });
  };

  return (
    <div
      style={{
        borderBottom: '1px solid #e0e0e0',
        paddingBottom: '8px',
        width: '100%',
      }}
    >
      <div>
        <Select
          placeholder="Add a category"
          onChange={handleAddCategoryChange}
          menuPlacement={'top'}
          options={filteredCategories}
          value={null}
        />
      </div>
      <div>
        {selectedCategories.map((category) => {
          const entryFee = category.entryFee
            ? formatNumberAsCurrency(category.entryFee, event.currencyCode)
            : 'Free';
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                margin: '16px 0',
              }}
              key={category.cuid}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <div style={{ display: 'flex', marginRight: '12px' }}>
                  <IconButton onClick={(): void => handleRemoveCategory(category.cuid)}>
                    <SVGIcon
                      focusable="false"
                      viewBox="0 0 24 24"
                      color="#C8C8C8"
                      aria-hidden="true"
                    >
                      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z" />
                    </SVGIcon>
                  </IconButton>
                </div>
                <Typography>
                  <b>
                    {eventDisciplines.length > 1
                      ? category.eventDiscipline.disciplineName + ' - ' + category.name
                      : category.name}
                  </b>
                </Typography>
              </div>
              <Typography>{entryFee}</Typography>
            </div>
          );
        })}
      </div>

      <div style={{ marginTop: '8px', display: 'flex' }}>
        <SmallButton
          style={{ flexGrow: 1 }}
          className={!isRegistrationOpen ? 'disabled' : undefined}
          target={'_blank'}
          rel={'noopener nofollow'}
          href={registerLink}
        >
          {isRegistrationOpen ? 'Register' : 'Registration Closed'}
        </SmallButton>
      </div>
    </div>
  );
};

export default RacesContainer;
